<template>
  <v-form autocomplete="off" @submit.prevent="$emit('submit')">
    <v-text-field :value="definition.term" label="Термин" @input="setField('term', $event)" />

    <wysiwyg-editor :value="definition.definition" @input="setField('definition', $event)">
      <template v-slot:title>Определение</template>
    </wysiwyg-editor>

    <div class="my-5">
      <v-text-field :value="definition.url" label="URL" @input="setField('url', $event)" />
      <small><b>Ссылка на термин: </b>{{ url }}</small>
    </div>

    <v-text-field :value="definition.seoTitle" label="Title страницы (SEO)" @input="setField('seoTitle', $event)" />

    <v-text-field
      :value="definition.seoDescription"
      label="Description страницы (SEO)"
      @input="setField('seoDescription', $event)"
    />

    <v-text-field :value="definition.seoHeader" label="H1 страницы (SEO)" @input="setField('seoHeader', $event)" />

    <v-checkbox v-model="isIndexableProxy" label="Индексация" />

    <div class="mt-4 text-right">
      <v-btn type="submit" :loading="loading" color="accent">Сохранить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { Definition } from "~/libs/definition/definition";
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    definition: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    url() {
      return Definition.createFullUrl(this.definition.url);
    },

    isIndexableProxy: {
      get() {
        return this.definition.isIndexable;
      },
      set(value) {
        this.setField("isIndexable", value);
      }
    }
  }
};
</script>
