export const createDefinitionDto = {
  term: "",
  definition: "",
  url: "",
  seoTitle: "",
  seoHeader: "",
  seoDescription: "",
  isIndexable: true
};

Object.freeze(createDefinitionDto);
