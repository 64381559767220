<template>
  <div>
    <h2 class="mb-4">Новый термин</h2>

    <v-sheet class="pa-4" elevation="3">
      <definition-form
        :definition="definition"
        :loading="loading"
        @change-field="changeField"
        @submit="createDefinition"
      />
    </v-sheet>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { ApiValidationError } from "~/core/api-validation-error";
import { createDefinitionDto } from "~/libs/definition/create-definition-dto";

import DefinitionForm from "~/components/definitions/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      loading: false,

      definition: { ...createDefinitionDto }
    };
  },
  methods: {
    async createDefinition() {
      this.loading = true;

      try {
        await this.$axios.$post("definitions", this.definition);

        this.clear();

        this.$snackbar.success("Термин создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.definition)).toHtml();

        this.$snackbar.error("Не удалось создать термин: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.definition = { ...createDefinitionDto };
    },

    changeField(payload) {
      this.commitChange(this.definition, payload);
    }
  },
  components: {
    DefinitionForm
  }
};
</script>
